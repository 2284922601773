<template>
  <div class="main">
    <header class="header">
      {{dataList.filter(e=>e.type == 2 && e.level==1)[0]?.fileName}}
    </header>

    <div class="mid">
      <div class="mid-l">
        <div class="mid-l-title">
          <div class="mid-l-title-in">
            <div class="mid-l-title-in-text">
              <div class="trianglel"></div>
              <span>简介</span>
            </div>
          </div>
        </div>

        <div class="mid-l-body">
          <vue-seamless-scroll :class-option="nextAge" :data="dataList.filter(e=>e.type == 2 && e.level==2)" class="seamless-warp">
            <!--            <ul>-->
            <!--              <li v-for="(item,index) in listData" :key="index">-->
            <!--                <span>{{item.fileUrl}}</span>-->
            <!--              </li>-->
            <!--            </ul>-->
            <div>{{dataList.filter(e=>e.type == 2 && e.level==2)[0]?.fileUrl}}</div>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="mid-r">
        <video controls muted="muted" autoplay :src="dataList.filter(e=>e.type == 0 && e.level==1)[0]?.fileUrl" />
        <!--        <el-carousel indicator-position="outside" height="500px">-->
        <!--          <el-carousel-item v-for="item in 4" :key="item">-->
        <!--            <div class="imgbox">-->

        <!--            </div>-->
        <!--          </el-carousel-item>-->
        <!--        </el-carousel>-->
      </div>
    </div>

    <div class="bom">
      <div class="bom-in">
        <div class="bom-box" v-for="(item,index) in dataList.filter(e=> e.type== 2 && e.level == 3)" :key="index">
          <div class="bom-in-title">
            <div class="bom-in-title-line l"></div>
            <span>{{item.fileName}}</span>
            <div class="bom-in-title-line r"></div>
          </div>
          <div class="bom-in-body">
            <div class="bom-in-body-line"></div>
            <div class="bom-in-body-text">
              <vue-seamless-scroll :data="dataList.filter(e=> e.type== 2 && e.level == 3)" class="seamless-text-warp">
                {{item.fileUrl}}
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getData} from "../../api/screen/synopsis";
import {nextTick, onMounted, reactive, toRefs, c, computed} from "vue";
export default {
  setup(){
    let state = reactive({
      dataList:[],
      listData: [],
    })

    const nextAge = computed(() => {
      return{
        openWatch: true,
        limitMoveNum: state.listData.length, // 开始无缝滚动的数据量 this.dataList.length
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    })
    onMounted(()=>{
      document.body.style.setProperty('--header-margin-top',43 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--mid-height',540 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--seamless-warp',375 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--bom-in-width',document.body.clientWidth + 'px');
      document.body.style.setProperty('--bom-box-width',document.body.clientWidth / 5 - 10 + 'px');
      document.body.style.setProperty('--mid-mid-width', 876 / 1920 * document.body.clientWidth + 'px');
      document.body.style.setProperty('--bom-in-height', 337 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--seamless-text-warp', 280 / 1080 * document.body.clientHeight + 'px');
      getData().then(res=>{
        nextTick(()=>{
          state.dataList = res.data.data.screenVideoList;
          state.listData.push(state.dataList.filter(e=>e.type == 2 && e.level==2)[0])
        })
      })
    })
    return{
      nextAge,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>

.main{
  width: 100%;
  .header{
    margin-top: var(--header-margin-top);/*no*/
    font-size: 60px;
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing:20px;/*no*/
  }
  .mid{
    height: var(--mid-height);/*no*/
    margin: 0 20px;/*no*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mid-l{
      width: var(--mid-mid-width);/*no*/
      height: var(--mid-height);/*no*/
      .mid-l-title{
        border-left: 2px solid #00FFC6;/*no*/
        width: 387px;/*no*/
        height: 80px;/*no*/
        background: linear-gradient(270deg, rgba(10, 70, 85, 0) 0%, #125945 100%);
        opacity: 0.8;
        font-size: 44px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;/*no*/
        .mid-l-title-in{
          width: 100%;
          line-height: 64px;/*no*/
          height: 64px;/*no*/
          background: linear-gradient(270deg, rgba(10, 70, 85, 0) 0%, #126B51 100%);
          .mid-l-title-in-text{
            display: flex;
            align-items: center;
            .trianglel{
              margin-left: 40px;/*no*/
              display: inline-block;
              width:0;
              height:0;
              border-top:20px solid transparent;/*no*/
              border-right:20px solid transparent;/*no*/
              border-bottom:20px solid transparent;/*no*/
              border-left:20px solid #11FFBF; /*no*/
            }
          }
        }
      }
      .mid-l-body{
        width: 100%;
        font-size: 36px;
        color: #FFFFFF;
        text-indent:36px;/*no*/
        text-align: left;
        .seamless-warp{
          height: var(--seamless-warp);/*no*/
          overflow: hidden;
        }
        //&::-webkit-scrollbar {
        //  display: none;
        //}
      }
    }
    .mid-r{
      width: 930px;/*no*/
      height: var(--mid-height);/*no*/
      >video{
        width: 100%;
        height: 100%;
      }
    }
  }

  .bom{
    height: var(--bom-in-height);/*no*/
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .bom-in{
      margin: 40px 40px 0 40px;
      width: var(--bom-in-width); /*no*/
      height: var(--bom-in-height);/*no*/
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 10px;/*no*/
      .bom-box{
        width: var(--bom-box-width); /*no*/
        .bom-in-title{
          width: 100%;
          height: 32px;/*no*/
          display: flex;
          align-items: center;
          justify-content: center;
          .bom-in-title-line{
            width: 100px;/*no*/
            height: 2px;/*no*/
            &.l{
              background:linear-gradient(to right,#071D1B,#C6C6C7);
              position: relative;
              margin-right: 10px;/*no*/
              &::after{
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
                right: 0;
                content: "";
                display: inline-block;
                width: 16px;/*no*/
                height: 16px;/*no*/
                background: #C6C6C7;
                border: 2px solid #FDFFF2;/*no*/
                border-radius: 50%;
              }
            }
            &.r{
              margin-left: 10px;/*no*/
              background:linear-gradient(to right,#C6C6C7,#071D1B);
              position: relative;
              &::after{
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
                left: 0;
                content: "";
                display: inline-block;
                width: 16px;/*no*/
                height: 16px;/*no*/
                background: #C6C6C7;
                border: 2px solid #FDFFF2;
                border-radius: 50%;
              }
            }
          }
          >span{
            font-size: 28px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
      .bom-in-body{
        margin-top: 18px;/*no*/
        width: 100%;
        height: calc(100% - 50px);/*no*/
        background: yellow;
        border: 1px solid #12634D; /*no*/
        .bom-in-body-line{
          width: 100%;
          height: 16px; /*no*/
          background: #0E3D34;
        }

        .bom-in-body-text{
          width: 100%;
          background: #0A2B25;
          font-size: 36px;
          color: #FFFFFF;
          text-indent:36px; /*no*/
          text-align: left;
          .seamless-text-warp{
            height: var(--seamless-text-warp); /*no*/
            overflow: hidden;
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}
</style>